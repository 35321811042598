import {useReactiveVar } from '@apollo/client'
import {FC, useMemo} from 'react';
import { SearchSelectInput } from "../components/core";
import { currentUserVar } from '../apollo/cache/auth';
import lodash from "lodash";

interface RegionPickerProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any
  setFieldValue: any;
  setFieldTouched?: any;
  setFieldError?: any;
  rawId?: boolean;
  position?: ("top" | "bottom");
}

export const RegionPicker: FC<RegionPickerProps> = ({ id, label, rawId, ...form }) => {
  const currentUser = useReactiveVar(currentUserVar);
  const allRegions = useMemo(
    () => currentUser.partner.districts.map((district) => district.region),
  [currentUser])
  const distinctRegions = lodash.sortBy(lodash.uniqBy(allRegions, '_id'), 'name')

  return (
    <SearchSelectInput
      id={id ?? "region"}
      label={label ?? "Region"}
      placeholder="Select Region"
      options={(distinctRegions ?? [])?.map((region: any) => ({
        label: { title: region.name as string },
        value: rawId ? region?._id : region
      }))}
      {...form}
      disabled={form.disabled}
    />
  )
}