import {
  CustomerTypes,
  Genders,
  ServiceClasses,
  ServiceRequestCategories,
  ServiceRequestTypes,
  ServiceTypes,
  Titles,
} from "apollo/data";
import { validators } from "utils";
import { ICustomerInfoFormSchema } from "../customer-info";
import { IIdentityInfoFormSchema } from "../identity-info";
import lodash from "lodash";
import * as Yup from "yup";

export type Title = (typeof Titles)[number];
export type Gender = (typeof Genders)[number];
export type RequestCategory =
  (typeof ServiceRequestCategories)[number]["value"];
export type RequestType = (typeof ServiceRequestTypes)[number]["value"];
export type CustomerType = (typeof CustomerTypes)[number];
export type ServiceType = (typeof ServiceTypes)[number]["value"];
export type ServiceClass = (typeof ServiceClasses)[number]["value"];

export interface ICreateServiceRequestFormSchema {
  requestInfo: {
    isExistingCustomer: false;
    existingCustomer: any;
    existingServicePoint: any;
    isExistingProperty: false;
    existingProperty: any;
    category: RequestCategory;
    type: RequestType;
    quantity: number;
  };
  customerInfo: ICustomerInfoFormSchema;
  identityInfo: IIdentityInfoFormSchema;
  propertyInfo: {
    owner: {
      fullName: string;
      phoneNumber: string;
    };
    region: {
      _id: string;
      code: string;
      name: string;
    } | null;
    district: {
      _id: string;
      code: string;
      name: string;
    } | null;
    ghanaPostAddress: string;
    community: string;
    streetName: string;
    houseNumber: string;
    structureNumber: string;
    landmark: string;
    premiseType: {
      _id: string;
      code: string;
      name: string;
    } | null;
    premiseCategory: {
      _id: string;
      code: string;
      name: string;
    } | null;
    activity: {
      _id: string;
      code: string;
      name: string;
    } | null;
    subActivity: {
      _id: string;
      code: string;
      name: string;
    } | null;
    geoLocation: {
      longitude: number;
      latitude: number;
    };
    sitePlanDocumentUrl: string;
  };
  serviceInfo: {
    serviceType: ServiceType;
    serviceClass: ServiceClass;
    energyCertificateNumber: string;
    energyCertificateIssuerPhone: string;
    energyCertificateDocumentUrl: string;
  };
}

export const RequestInfoSchema = Yup.object().shape({
  category: Yup.string()
    .oneOf(lodash.map(ServiceRequestCategories, "value"))
    .required(),
  type: Yup.string().oneOf(lodash.map(ServiceRequestTypes, "value")).required(),
  quantity: Yup.number().min(0).max(3).notRequired(),
  existingCustomer: Yup.object()
    .when("type", {
      is: "NewServiceRequest",
      then: (schemaa) =>
        schemaa.when("isExistingCustomer", {
          is: true,
          then: (schema) => schema.nullable().required(),
          otherwise: (schema) => schema.nullable().notRequired(),
        }),
      otherwise: (schemaa) => schemaa.nullable().required(),
    })
    .nullable(),
  existingProperty: Yup.object()
    .when("type", {
      is: "NewServiceRequest",
      then: (schemaa) => schemaa.nullable().notRequired(),
      otherwise: (schemaa) => schemaa.nullable().required(),
    })
    .nullable(),
});

export const PropertyInfoSchema = Yup.object().shape({
  owner: Yup.object()
    .shape({
      fullName: Yup.string().required("Kindly enter property owner's name"),
      phoneNumber: Yup.string()
        .matches(
          /^0(2|5)(0|2|3|4|5|6|7|9)\d{7}$/,
          "Kindly enter a valid phone number",
        )
        .required("Phone number is required"),
    })
    .required(),
  district: Yup.object().nullable().required("Kindly select district"),
  ghanaPostAddress: Yup.string()
    .matches(
      validators.GhanaPostRegex,
      "Kindly enter a valid GhanaPost Address",
    )
    .required("GhanaPost Address is required"),
  community: Yup.string().required("Kindly enter community"),
  streetName: Yup.string().required("Kindly enter street name"),
  houseNumber: Yup.string().required("Kindly enter house number"),
  structureNumber: Yup.string().notRequired(),
  landmark: Yup.string().required("Kindly enter closest landmark"),
  premiseType: Yup.object().nullable().required("Kindly select premise type"),
  premiseCategory: Yup.object()
    .nullable()
    .required("Kindly select premise category"),
  activity: Yup.object().nullable().required("Kindly select activity"),
  subActivity: Yup.object().nullable().required("Kindly select sub activity"),
  geoLocation: Yup.object().shape({
    longitude: Yup.number().min(-180).max(180).required(),
    latitude: Yup.number().min(-180).max(180).required(),
  }),
  sitePlanDocumentUrl: Yup.string().url().notRequired(),
});

export const ServiceInfoSchema = Yup.object().shape({
  serviceType: Yup.string().oneOf(lodash.map(ServiceTypes, "value")).required(),
  serviceClass: Yup.string()
    .oneOf(lodash.map(ServiceClasses, "value"))
    .required(),
  energyCertificateNumber: Yup.string().required(
    "Energy certificate number is required",
  ),
  energyCertificateIssuerPhone: Yup.string().matches(
    /^0(2|5)(0|2|3|4|5|6|7|9)\d{7}$/,
    "Kindly enter a valid phone number",
  ),
  energyCertificateDocumentUrl: Yup.string()
    .url()
    .required("Kindly attach energy certificate document"),
});

export const sanitizeCustomer = (customer: any) => ({
  customerType: customer.customerType || "Individual",

  organization: customer.organization
    ? lodash.pick(customer.organization, ["name"])
    : {
        name: "",
      },
  representative: customer.representative
    ? lodash.pick(customer.representative, [
        "title",
        "fullName",
        "nationality",
        "dateOfBirth",
        "gender",
        "phoneNumber",
        "emailAddress",
        "profileImageUrl",
      ])
    : {
        title: "Mr",
        fullName: "",
        nationality: "Ghanaian",
        dateOfBirth: "",
        gender: "Male",
        phoneNumber: "",
        emailAddress: "",
        profileImageUrl: "",
      },
});

export const sanitizeIdentity = (identity: any) => ({
  representative: identity.representative
    ? lodash.pick(identity.representative, [
        "hasGhanaCard",
        "ghanaCardNumber",
        "ghanaCardIssueDate",
        "ghanaCardExpiryDate",
        "ghanaCardFrontImageUrl",
        "ghanaCardBackImageUrl",
        "identityCardType",
        "identityCardNumber",
        "identityCardIssueDate",
        "identityCardExpiryDate",
        "identityCardFrontImageUrl",
        "identityCardBackImageUrl",
      ])
    : {
        hasGhanaCard: true,
        ghanaCardNumber: "",
        ghanaCardIssueDate: "",
        ghanaCardExpiryDate: "",
        ghanaCardFrontImageUrl: "",
        ghanaCardBackImageUrl: "",
        identityCardType: "DriversLicense",
        identityCardNumber: "",
        identityCardIssueDate: "",
        identityCardExpiryDate: "",
        identityCardFrontImageUrl: "",
        identityCardBackImageUrl: "",
      },
  organization: identity.organization
    ? lodash.pick(identity.organization, [
        "taxIdentificationNumber",
        "organizationRegistrationNumber",
        "organizationRegistrationDate",
        "organizationRegistrationDocumentUrl",
        "certificateOfIncorporationDocumentUrl",
      ])
    : {
        taxIdentificationNumber: "",
        organizationRegistrationNumber: "",
        organizationRegistrationDate: "",
        organizationRegistrationDocumentUrl: "",
        certificateOfIncorporationDocumentUrl: "",
      },
});

export const sanitizeProperty = (property: any) => ({
  owner: {
    fullName: property?.owner?.fullName || "",
    phoneNumber: property?.owner?.phoneNumber || "",
  },
  region: property?.region || null,
  district: property?.district || null,
  ghanaPostAddress: property?.ghanaPostAddress || "",
  community: property?.community || "",
  streetName: property?.streetName || "",
  houseNumber: property?.houseNumber || "",
  structureNumber: property?.structureNumber || "",
  landmark: property?.landmark || "",
  premiseType: property?.premiseType || null,
  premiseCategory: property?.premiseCategory || null,
  activity: property?.activity || null,
  subActivity: property?.subActivity || null,
  geoLocation: property?.geoLocation?.coordinates
    ? {
        longitude: property?.geoLocation?.coordinates?.[0],
        latitude: property?.geoLocation?.coordinates?.[1],
      }
    : {
        longitude: -0.234361,
        latitude: 5.667032,
      },
  sitePlanDocumentUrl: property?.sitePlanDocumentUrl || "",
});

export const sanitizeService = (service: any) => ({
  serviceType: service?.serviceType || "Postpaid",
  serviceClass: service?.serviceClass || "Residential",
  energyCertificateNumber: service?.energyCertificateNumber || "",
  energyCertificateDocumentUrl: service?.energyCertificateDocumentUrl || "",
});
