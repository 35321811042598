import { gql } from "@apollo/client";

export const GET_SERVICE_REQUESTS = gql`
  query GetServiceRequests(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $status: ServiceRequestStatus
    $category: ServiceRequestCategoryName
    $region: ID
    $district: ID
    $fromDate: Date
    $toDate: Date
  ) {
    rows: getServiceRequests(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      status: $status
      category: $category
      fromDate: $fromDate
      toDate: $toDate
    ) {
      _id
      code
      isBulk
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      request {
        customer {
          customerType
          representative {
            fullName
            phoneNumber
          }
          organization {
            name
          }
        }
        property {
          ghanaPostAddress
          community
        }
        service {
          serviceType
          serviceClass
        }
      }
      category
      type
      status
      createdAt
      updatedAt
    }
    count: getServiceRequestsCount(
      region: $region
      district: $district
      search: $search
      searchFields: $searchFields
      status: $status
      category: $category
      fromDate: $fromDate
      toDate: $toDate
    )
  }
`;

export const GET_SERVICE_REQUEST = gql`
  query GetServiceRequest($id: ID!) {
    serviceRequest: getServiceRequest(id: $id) {
      _id
      code
      category
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      request {
        customer {
          customerType

          organization {
            name
            taxIdentificationNumber
            organizationRegistrationNumber
            organizationRegistrationDate
            organizationRegistrationDocumentUrl
            certificateOfIncorporationDocumentUrl
          }
          representative {
            title
            fullName
            nationality
            dateOfBirth
            gender
            phoneNumber
            emailAddress
            profileImageUrl
            hasGhanaCard
            ghanaCardNumber
            ghanaCardIssueDate
            ghanaCardExpiryDate
            ghanaCardFrontImageUrl
            ghanaCardBackImageUrl
            identityCardType
            identityCardNumber
            identityCardIssueDate
            identityCardExpiryDate
            identityCardFrontImageUrl
            identityCardBackImageUrl
          }
        }
        property {
          owner {
            fullName
            phoneNumber
          }
          region {
            _id
            code
            name
          }
          district {
            _id
            code
            name
          }
          ghanaPostAddress
          community
          streetName
          houseNumber
          structureNumber
          landmark
          premiseType {
            _id
            code
            name
          }
          premiseCategory {
            _id
            code
            name
          }
          activity {
            _id
            code
            name
          }
          subActivity {
            _id
            code
            name
          }
          geoLocation {
            type
            coordinates
          }
          sitePlanDocumentUrl
        }
        service {
          serviceType
          serviceClass
          serviceQuantity
          energyCertificateNumber
          energyCertificateDocumentUrl
        }
      }
      type
      status
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_SERVICE_REQUEST = gql`
  mutation UpdateServiceRequest(
    $id: ID!
    $fullName: String
    $phoneNumber: String
    $serviceType: String
    $serviceClass: String
  ) {
    updateServiceRequest(
      id: $id
      fullName: $fullName
      phoneNumber: $phoneNumber
      serviceType: $serviceType
      serviceClass: $serviceClass
    ) {
      _id
    }
  }
`;
